import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogContent, DialogTitle, Switch, TextField, Typography } from '@mui/material'
import { Form, Formik } from "formik";
import { Avatar } from "../../components/Avatar/Avatar";
import { ServiceSelect } from "./ServiceSelect";
import { ServiceTypeSelect } from "./ServiceTypeSelect";
import * as Yup from 'yup'
import {
  useCreateServiceMutation,
  useGetServiceQuery,
  useUpdateServiceMutation,
  useUploadServiceIconMutation
} from "../../redux/api/services/service.api";
import services from "./services";

interface NewServiceModalProps {
  onSuccess: () => void
  onCanceled?: () => void
  id?: string
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  parent_id: Yup.string().optional(),
  description: Yup.string().required('Description is required'),
  type: Yup.string().required('Service type is required'),
  is_basic: Yup.boolean().required('Basic service is required'),
})

const initialValues = {
  name: '',
  parent_id: undefined,
  description: '',
  type: '',
  is_basic: false,
}

export const NewServiceModal = ({ onSuccess, id, onCanceled }: NewServiceModalProps) => {
  const [code, setActivationCode] = useState('')
  const [price, setPrice] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const onClose = () => {
    setOpen(false)
    onCanceled?.()
  }

  const closeModal = () => {
    resetModalFields()
    onClose()
  }

  const resetModalFields = () => {
    setActivationCode('')
    setPrice('')
  }

  const openModal = () => {
    setOpen(true)
  }

  useEffect(() => {
    if (id !== undefined) {
      openModal()
    } else {
      closeModal()
    }
  }, [id]);

  const [uploadIcon] = useUploadServiceIconMutation()

  const [createService] = useCreateServiceMutation()

  const [updateService] = useUpdateServiceMutation()

  const { data: service } = useGetServiceQuery(id as string, {
    skip: !id
  })

  const onSubmit = async (values: any) => {
    setIsLoading(true)
    if (id) {
      await updateService({
        id,
        name: values.name,
        parent_id: values.parent_id,
        description: values.description,
        type: values.type,
        is_basic: values.is_basic,
        description_symptoms: values.description_symptoms,
      }).unwrap()
      if (file) {
        await uploadIcon({
          id,
          file,
        }).unwrap()
      }
    } else {
      const { id } = await createService(values).unwrap()
      if (file) {
        await uploadIcon({
          id,
          file,
        }).unwrap()
      }
    }
    setIsLoading(false)
    closeModal()
    onSuccess()
  }

  return (
    <>
      <Button variant="contained" color="warning" onClick={openModal}>
        New service
      </Button>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle fontWeight={600}>{id ? 'Editing service' : 'New service'}</DialogTitle>
        <DialogContent style={{
          width: '400px',
        }}>
          {/* @ts-ignore */}
          <Formik initialValues={id ? service : initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
            {({ values, handleChange, setFieldValue, handleSubmit, errors }) => {
              if (!values) return null
              return (
              <Form>
                <div>
                  <Box style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                  }}>
                    <Avatar defaultUrl={''} onFileSelected={setFile} />
                    <Box>
                      <Typography variant="caption" style={{ marginBottom: '10px' }}>
                        Basic service
                      </Typography>
                      <Switch
                        checked={values.is_basic}
                        onChange={(e) => setFieldValue('is_basic', e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <Typography variant="subtitle1" gutterBottom>
                      Name
                    </Typography>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      size='small'
                      value={values.name}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                    />
                  </Box>
                  <Box mt={3}>
                    <Typography variant="subtitle1" gutterBottom>
                      Parent service
                    </Typography>
                    <ServiceSelect value={values.parent_id} onChange={(value) => setFieldValue('parent_id', value)} />
                  </Box>
                  <Box mt={3}>
                    <Typography variant="subtitle1" gutterBottom>
                      Service type
                    </Typography>
                    <ServiceTypeSelect value={values.type} onChange={(value) => setFieldValue('type', value)} />
                  </Box>
                  <Box mt={3}>
                    <Typography variant="subtitle1" gutterBottom>
                      Description
                    </Typography>
                    <TextField
                      rows={4}
                      multiline
                      fullWidth
                      label="Description"
                      name="description"
                      size='small'
                      value={values.description}
                      onChange={(e) => setFieldValue('description', e.target.value)}
                    />
                  </Box>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                  <Button
                    onClick={closeModal}
                    type="button"
                    color="inherit"
                    sx={{
                      mt: 2,
                      mb: 1,
                      marginRight: '10px',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    itemType='submit'
                    color="warning"
                    type="button"
                    variant="contained"
                    onClick={() => handleSubmit()}
                    sx={{
                      mt: 2,
                      mb: 1,
                      fontFamily: 'Montserrat',
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}
