import React, { CSSProperties, useEffect, useState } from 'react'
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material'
import Logo from '../../assets/images/logo.png'
import { ReactComponent as RequestIcon } from '../../assets/images/request.svg'
import { ReactComponent as PromoIcon } from '../../assets/images/promo.svg'
import { ReactComponent as ServicesIcon } from '../../assets/images/services.svg'
import Avatar from '../../assets/images/avatar.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as LogoutIcon } from '../../assets/images/log_out.svg'
import { ReactComponent as SubscriptionIcon } from '../../assets/images/subscription.svg'
import { ReactComponent as VehicleIcon } from '../../assets/images/vehicle.svg'
import { ReactComponent as MaintenanceIcon } from '../../assets/images/maintenance.svg'
import { ReactComponent as DiagnosticReportIcon } from '../../assets/images/diagnostic_report.svg'
import { ReactComponent as InvoiceReportIcon } from '../../assets/images/invoice.svg'
import { ReactComponent as ConsumerIcon } from '../../assets/images/consumer.svg'
import { apiCall } from '../api/api'

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [user, setUser] = useState<any>(null)
  const navigate = useNavigate()
  const [activeItem, setActiveItem] = useState('')
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname.split('/')[1]
    setActiveItem(path)
  }, [location])

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
    setActiveItem('')
  }

  const iconStyle = (itemName: string): CSSProperties => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 48,
    height: 48,
    padding: 12,
    borderRadius: 6,
    cursor: 'pointer',
    boxSizing: 'border-box',
    backgroundColor: itemName === activeItem && itemName !== '' ? '#FFF8F5' : 'transparent',
    fill: itemName === activeItem ? '#ED6C02' : '#707070',
  })

  const handleNavigation = (event: any, item: string) => {
    event.preventDefault()
    setActiveItem(item)
  }

  useEffect(() => {
    if (activeItem) {
      navigate(`/${activeItem}`)
    }
  }, [activeItem, navigate])

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiCall('admin/auth/is-auth', 'get')
        setUser(response)
      } catch (error) {
        console.error('Failed to fetch username:', error)
      }
    }

    fetchUser()
  }, [])

  const handleLogout = async () => {
    try {
      await apiCall(`admin/auth/logout`, 'post')
      localStorage.removeItem('userToken')
      navigate('/')
    } catch (error) {
      console.error('Failed to logout:', error)
    }
  }

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      open={isOpen}
      onClose={toggleDrawer}
      style={{
        width: isOpen ? 250 : 0,
        flexShrink: 0,
        position: 'relative',
      }}
    >
      <div>
        <ListItem onClick={toggleDrawer}>
          <ListItemIcon
            style={iconStyle('')}
            className={`iconHover ${activeItem === 'logo' ? 'activeItem' : ''}`}
          >
            <img src={Logo} alt="logo" width={36} height={36} />
          </ListItemIcon>
          {isOpen && <ListItemText primary="Dashboard" />}
        </ListItem>
      </div>
      <List key={activeItem}>
        <ListItem onClick={(event) => handleNavigation(event, 'home')}>
          <ListItemIcon
            style={iconStyle('home')}
            className={`iconHover ${activeItem === 'home' ? 'activeItem' : ''}`}
          >
            <RequestIcon />
          </ListItemIcon>
          {isOpen && <ListItemText primary="Requests" />}
        </ListItem>
      </List>
      <List>
        <ListItem onClick={(event) => handleNavigation(event, 'services')}>
          <ListItemIcon
            style={iconStyle('services')}
            className={`iconHover ${activeItem === 'services' ? 'activeItem' : ''}`}
          >
            <ServicesIcon />
          </ListItemIcon>
          {isOpen && <ListItemText primary="Services" />}
        </ListItem>
      </List>
      {/* <List>
        <ListItem onClick={(event) => handleNavigation(event, 'promo')}>
          <ListItemIcon
            style={iconStyle('promo')}
            className={`iconHover ${activeItem === 'promo' ? 'activeItem' : ''}`}
          >
            <PromoIcon />
          </ListItemIcon>
          {isOpen && <ListItemText primary="Promo Codes" />}
        </ListItem>
      </List> */}
      <List>
        <ListItem onClick={(event) => handleNavigation(event, 'subscription')}>
          <ListItemIcon
            style={iconStyle('subscription')}
            className={`iconHover ${activeItem === 'subscription' ? 'activeItem' : ''}`}
          >
            <SubscriptionIcon />
          </ListItemIcon>
          {isOpen && <ListItemText primary="Business Subscriptions" />}
        </ListItem>
      </List>
      {/* <List>
        <ListItem onClick={(event) => handleNavigation(event, 'consumer')}>
          <ListItemIcon
            style={iconStyle('consumer')}
            className={`iconHover ${activeItem === 'consumer' ? 'activeItem' : ''}`}
          >
            <ConsumerIcon />
          </ListItemIcon>
          {isOpen && <ListItemText primary="Consumer List" />}
        </ListItem>
      </List> */}
      <List>
        <ListItem onClick={(event) => handleNavigation(event, 'vehicle')}>
          <ListItemIcon
            style={iconStyle('vehicle')}
            className={`iconHover ${activeItem === 'vehicle' ? 'activeItem' : ''}`}
          >
            <VehicleIcon />
          </ListItemIcon>
          {isOpen && <ListItemText primary="Customer vehicles" />}
        </ListItem>
      </List>
      <List>
        <ListItem onClick={(event) => handleNavigation(event, 'maintenance')}>
          <ListItemIcon
            style={iconStyle('maintenance')}
            className={`iconHover ${activeItem === 'maintenance' ? 'activeItem' : ''}`}
          >
            <MaintenanceIcon />
          </ListItemIcon>
          {isOpen && <ListItemText primary="Vehicle maintenance" />}
        </ListItem>
      </List>
      <List>
        <ListItem onClick={(event) => handleNavigation(event, 'diagnostic_report')}>
          <ListItemIcon
            style={iconStyle('diagnostic_report')}
            className={`iconHover ${activeItem === 'diagnostic_report' ? 'activeItem' : ''}`}
          >
            <DiagnosticReportIcon />
          </ListItemIcon>
          {isOpen && <ListItemText primary="Diagnostic reports" />}
        </ListItem>
      </List>
      <List>
        <ListItem onClick={(event) => handleNavigation(event, 'invoice_report')}>
          <ListItemIcon
            style={iconStyle('invoice_report')}
            className={`iconHover ${activeItem === 'invoice_report' ? 'activeItem' : ''}`}
          >
            <InvoiceReportIcon />
          </ListItemIcon>
          {isOpen && <ListItemText primary="Invoice reports" />}
        </ListItem>
      </List>
      <div style={{ height: 'auto', padding: '32px 0px 0px 0px', gap: 24, marginTop: 'auto' }}>
        <ListItem onClick={toggleDrawer}>
          <ListItemIcon
            style={iconStyle('home')}
            className={`iconHover ${activeItem === 'home' ? 'activeItem' : ''}`}
          >
            <img src={Avatar} alt="Profile" width={48} height={48} />
          </ListItemIcon>
          {isOpen && (
            <div style={{ display: 'flex' }}>
              <div>
                <ListItemText primary={user.name} />
                <ListItemText primary={user.email} />
              </div>

              <IconButton onClick={handleLogout}>
                <LogoutIcon />
              </IconButton>
            </div>
          )}
        </ListItem>
      </div>
    </Drawer>
  )
}

export default Sidebar
