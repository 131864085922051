import { IconButton, styled } from '@mui/material'

export const StyledButton = styled(IconButton)`
  & {
    width: 35px;
    height: 35px;
  }
  ,
  &&:hover {
    width: 35px;
    height: 35px;
    background-color: #fff0eb;
  }
`
