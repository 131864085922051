import React, { ChangeEvent } from 'react';
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useListServicesQuery } from "../../redux/api/services/service.api";

type ServicesSelectProps = {
  value: string;
  onChange: (value: string) => void;
}

export const ServiceSelect = ({ value, onChange }: ServicesSelectProps) => {

  const { data, isFetching } = useListServicesQuery({
    page: 1,
    limit: 100,
  })

  const handleChange = (e: SelectChangeEvent<string>) => {
    onChange(e.target.value as string);
  }

  return (
    <Select
      size='small'
      fullWidth
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      label="Age"
      onChange={handleChange}
    >
      {data?.items.map((service) => <MenuItem key={service.id} value={service.id}>{service.name}</MenuItem>)}
    </Select>
  );
};
