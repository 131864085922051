import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
} from '@mui/material'

interface ApprovalModalProps {
  open: boolean
  onClose: () => void
  onConfirm: (explanation: string) => void
}

const ApprovalModal: React.FC<ApprovalModalProps> = ({ open, onClose, onConfirm }) => {
  const [explanation, setExplanation] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const handleConfirm = () => {
    setIsLoading(true)
    onConfirm(explanation)
  }

  const handleClose = () => {
    setIsLoading(false)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="approval-dialog-title"
      aria-describedby="approval-dialog-description"
    >
      <DialogTitle id="approval-dialog-title" sx={{ mt: 2, ml: 2, mr: 2 }}>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 600, color: '#101828' }}>
          Approve diagnostic report and send it to customer?
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ ml: 2, mr: 2 }}>
        <Typography gutterBottom id="approval-dialog-description" sx={{ color: '#475467' }}>
          Diagnostic results will be sent to customer for approval.
        </Typography>
        <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#101828', mt: 2, mb: 1 }}>
          Approval explanation for customer:
        </Typography>
        <TextField
          multiline
          rows={4}
          color="warning"
          variant="outlined"
          fullWidth
          placeholder="Write explanation here..."
          value={explanation}
          onChange={(e) => setExplanation(e.target.value)}
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions sx={{ mb: 2, ml: 2, mr: 2 }}>
        <Button onClick={handleClose} color="warning" variant="outlined" sx={{ width: '50%' }}>
          Back
        </Button>
        <Button
          onClick={handleConfirm}
          color="warning"
          variant="contained"
          sx={{ width: '50%' }}
          disabled={!explanation.trim() || isLoading}
        >
          {isLoading ? 'Processing...' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ApprovalModal
