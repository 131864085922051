import React, { useState } from 'react'
import { Modal, Box, Typography, Button, List, Divider, Link, Stack, Grid } from '@mui/material'
import { ReactComponent as PdfIcon } from '../../../assets/images/pdf.svg'
import { ReactComponent as DownloadIcon } from '../../../assets/images/download.svg'
import ApprovalModal from './approval'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { apiCall } from '../../../components/api/api'
import { ConsumerRequestOffer } from '../models/invoice_report'
import FeedbackForShopModal from './feedback_for_shop'
import { formatCurrency } from '../../../components/helpers/helper'
import { ArrowTooltip } from '../../../components/ui/arrow_tooltip'
import { StyledButton } from '../../../components/ui/styled_button'
import Copy from '../../../assets/images/contentCopy.svg'
import CopyFilled from '../../../assets/images/contentCopyFilled.svg'
import FilePreview from '../../../components/file/file_preview'
import Decimal from 'decimal.js'

interface InvoiceReportModalProps {
  open: boolean
  onClose: () => void
  offer: ConsumerRequestOffer | null
}

const InvoiceReportModal: React.FC<InvoiceReportModalProps> = ({ open, onClose, offer }) => {
  const [feedbackForShopModalOpen, setFeedbackForShopModalOpen] = useState(false)
  const [approvalModalOpen, setApprovalModalOpen] = useState(false)
  const [copiedVin, setCopiedVin] = useState<string | null>(null)
  const [copiedOrderId, setCopiedOrderId] = useState<string | null>(null)
  const [copiedVehicleId, setCopiedVehicleId] = useState<string | null>(null)
  const [copiedComment, setCopiedComment] = useState<string | null>(null)
  const navigate = useNavigate()
  if (!offer) return null

  const requestedServicesSubtotal = offer.offer.consumer_request.status_logs
    .filter((log) => log.status === 'REQUESTED')
    .flatMap((log) => log.services)
    .reduce((acc, service) => acc + parseFloat(service.price || '0'), 0)

  const additionalServicesSubtotal = offer.offer.consumer_request.status_logs
    .filter((log) => log.status === 'PENDING_APPROVAL')
    .flatMap((log) => log.services)
    .reduce((acc, service) => acc + parseFloat(service.price || '0'), 0)

  const subtotal = requestedServicesSubtotal + additionalServicesSubtotal

  const supplyFee = parseFloat(offer.offer.supply_fee || offer.offer.new_supply_fee || '0.00')

  const tax = parseFloat(offer.offer.tax || offer.offer.new_tax || '0.00')
  const total = subtotal + supplyFee + tax

  const handleOpenFeedbackForShopModal = () => {
    setFeedbackForShopModalOpen(true)
  }

  const handleCloseFeedbackForShopModal = () => {
    setFeedbackForShopModalOpen(false)
  }

  const handleOpenApprovalModal = () => setApprovalModalOpen(true)
  const handleApprovalCloseModal = () => setApprovalModalOpen(false)

  const handleApprovalConfirmAction = () => {
    const url = `admin/offer-invoice/${offer.consumer_request_offer_id}/approve`
    apiCall(url, 'post')
      .then((response) => {
        handleApprovalCloseModal()
        enqueueSnackbar('Invoice succesfully approved!', { variant: 'success' })
        navigate(0)
      })
      .catch((error) => {
        console.error('Error:', error)
        handleApprovalCloseModal()
        enqueueSnackbar('Failed to approve invoice:', { variant: 'error' })
        navigate(0)
      })
  }

  const handleRejectAction = async (instruction: string) => {
    const requestBody = {
      instruction,
    }

    try {
      await apiCall(
        `admin/offer-invoice/${offer.consumer_request_offer_id}/reject`,
        'post',
        JSON.stringify(requestBody)
      )
      handleCloseFeedbackForShopModal()
      enqueueSnackbar('Invoice succesfully sent back to the shop!', { variant: 'success' })
      navigate(0)
    } catch (error) {
      console.error('Error:', error)
      handleApprovalCloseModal()
      enqueueSnackbar('Failed to sent back to the shop:', { variant: 'error' })
      navigate(0)
    }
  }

  const handleCopyVin = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVin(vin)
        setTimeout(() => {
          setCopiedVin(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const handleCopyOrderId = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedOrderId(vin)
        setTimeout(() => {
          setCopiedOrderId(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const handleVehicleId = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVehicleId(vin)
        setTimeout(() => {
          setCopiedVehicleId(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const handleCopyComment = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedComment(vin)
        setTimeout(() => {
          setCopiedComment(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: '920px',
          width: 'auto',
          maxWidth: {
            xs: 'calc(100% - 20px)',
            sm: '920px',
            md: '1200px',
          },
          maxHeight: '90vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2, display: 'flex', alignItems: 'center', fontWeight: '600' }}
        >
          Invoice
        </Typography>
        <Divider sx={{ mb: 2, width: '100%' }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ borderRight: '1px solid #EAECF0' }}>
            <Typography sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
              <PdfIcon />
              <Typography sx={{ ml: 1, mr: 8 }}>{offer.name}</Typography>
              <Link
                href={offer.url}
                download
                target="_blank"
                sx={{
                  marginLeft: 'auto',
                  fontWeight: 'bold',
                  color: '#DB5D08',
                  textDecoration: 'none',
                  mr: 2,
                  whiteSpace: 'nowrap',
                }}
              >
                <DownloadIcon /> Download
              </Link>
            </Typography>
            <Divider />
            <Divider />

            {offer?.offer.consumer_request.files?.length > 0 ? (
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontWeight: '600', color: '#1D2939', mb: 1 }}>
                  Uploaded files by customer
                </Typography>
                <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
                  <FilePreview files={offer?.offer.consumer_request.files || []} />
                </Box>
              </Box>
            ) : (
              ''
            )}
            <Divider />

            <Box sx={{ mb: 4, mt: 2 }}>
              {offer?.offer.consumer_request.additional_information ? (
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>
                    Customer comments{' '}
                    {offer?.offer.consumer_request.additional_information ? (
                      <ArrowTooltip
                        title={
                          copiedComment === offer?.offer.consumer_request.additional_information
                            ? 'Copied!'
                            : ''
                        }
                      >
                        <StyledButton
                          onClick={(event) =>
                            handleCopyComment(
                              event,
                              offer?.offer.consumer_request.additional_information ?? ''
                            )
                          }
                        >
                          <img
                            src={
                              copiedComment === offer?.offer.consumer_request.additional_information
                                ? CopyFilled
                                : Copy
                            }
                            alt=""
                          />
                        </StyledButton>
                      </ArrowTooltip>
                    ) : (
                      ''
                    )}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                    {offer?.offer.consumer_request.additional_information}
                  </Typography>
                </Box>
              ) : (
                ''
              )}
              <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Shop</Typography>
              <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                {offer.offer.provider_branch.name}
              </Typography>
              <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Order ID</Typography>
              <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                {offer.offer.consumer_request.slug}{' '}
                {offer.offer.consumer_request.slug ? (
                  <ArrowTooltip
                    title={copiedOrderId === offer.offer.consumer_request.slug ? 'Copied!' : ''}
                  >
                    <StyledButton
                      onClick={(event) =>
                        handleCopyOrderId(event, offer.offer.consumer_request.slug ?? '')
                      }
                    >
                      <img
                        src={
                          copiedOrderId === offer.offer.consumer_request.slug ? CopyFilled : Copy
                        }
                        alt=""
                      />
                    </StyledButton>
                  </ArrowTooltip>
                ) : (
                  ''
                )}
              </Typography>
              <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Vehicle</Typography>
              <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                {`${offer.offer.consumer_request.vehicle?.year} ${offer.offer.consumer_request.vehicle?.make} ${offer.offer.consumer_request.vehicle?.model}` ||
                  'N/A'}
              </Typography>
              <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Mileage</Typography>
              <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                {offer.offer.in_mileage.toLocaleString()} miles
              </Typography>
              <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Engine size</Typography>
              <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                {offer.offer.consumer_request.vehicle.engine_size}
              </Typography>
              <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>Vehicle ID</Typography>
              <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                {offer.offer.consumer_request.vehicle?.name}{' '}
                {offer.offer.consumer_request.vehicle?.name ? (
                  <ArrowTooltip
                    title={
                      copiedVehicleId === offer.offer.consumer_request.vehicle?.name
                        ? 'Copied!'
                        : ''
                    }
                  >
                    <StyledButton
                      onClick={(event) =>
                        handleVehicleId(event, offer.offer.consumer_request.vehicle?.name ?? '')
                      }
                    >
                      <img
                        src={
                          copiedVehicleId === offer.offer.consumer_request.vehicle?.name
                            ? CopyFilled
                            : Copy
                        }
                        alt=""
                      />
                    </StyledButton>
                  </ArrowTooltip>
                ) : (
                  ''
                )}
              </Typography>
              <Typography sx={{ fontWeight: '600', color: '#1D2939' }}>VIN</Typography>
              <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                {offer.offer.consumer_request.vehicle?.vin}{' '}
                {offer.offer.consumer_request.vehicle?.vin ? (
                  <ArrowTooltip
                    title={copiedVin === offer.offer.consumer_request.vehicle?.vin ? 'Copied!' : ''}
                  >
                    <StyledButton
                      onClick={(event) =>
                        handleCopyVin(event, offer.offer.consumer_request.vehicle?.vin ?? '')
                      }
                    >
                      <img
                        src={
                          copiedVin === offer.offer.consumer_request.vehicle?.vin
                            ? CopyFilled
                            : Copy
                        }
                        alt=""
                      />
                    </StyledButton>
                  </ArrowTooltip>
                ) : (
                  ''
                )}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            {offer.offer.consumer_request.status_logs.filter((log) => log.status === 'REQUESTED')
              .length > 0 && (
              <>
                {' '}
                <Typography sx={{ fontWeight: '600', mb: 2 }}>
                  Initially requested services
                </Typography>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  {offer.offer?.consumer_request.status_logs
                    .filter((log) => log.status === 'REQUESTED')
                    .flatMap((log) => log.services)
                    .map((item: any) => (
                      <Box
                        key={item.service_id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 2,
                          p: 1,
                          borderRadius: '12px',
                          border: '1px solid #EAECF0',
                        }}
                      >
                        <Stack spacing={1}>
                          <Typography
                            sx={{
                              fontSize: '16px',

                              fontWeight: '600',
                              color: '#393A3D',
                            }}
                          >
                            {item.service.parent.parent_id
                              ? item.service.parent.name
                              : item.service.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {item.service.parent.parent_id && item.service.name}
                          </Typography>
                        </Stack>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#393A3D',
                          }}
                        >
                          {formatCurrency(new Decimal(item.price))}
                        </Typography>
                      </Box>
                    ))}
                </List>
              </>
            )}
            {offer.offer.consumer_request.status_logs.filter(
              (log) => log.status === 'PENDING_APPROVAL'
            ).length > 0 && (
              <>
                {' '}
                <Typography sx={{ fontWeight: '600', mb: 2 }}>Recommended services</Typography>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  {offer.offer?.consumer_request.status_logs
                    .filter((log) => log.status === 'PENDING_APPROVAL')
                    .flatMap((log) => log.services)
                    .map((item: any) => (
                      <Box
                        key={item.service_id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 2,
                          p: 1,
                          borderRadius: '12px',
                          border: '1px solid #EAECF0',
                        }}
                      >
                        <Stack spacing={1}>
                          <Typography
                            sx={{
                              fontSize: '16px',

                              fontWeight: '600',
                              color: '#393A3D',
                            }}
                          >
                            {item.service.parent.parent_id
                              ? item.service.parent.name
                              : item.service.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {item.service.parent.parent_id && item.service.name}
                          </Typography>
                        </Stack>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                            color: '#393A3D',
                          }}
                        >
                          {formatCurrency(parseFloat(item.price))}
                        </Typography>
                      </Box>
                    ))}
                </List>
              </>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography
                sx={{
                  fontWeight: '600',
                  color: '#393A3D',
                  width: '100%',
                }}
              >
                Subtotal:
              </Typography>
              <Typography
                sx={{
                  fontWeight: '600',
                  color: '#DB5D08',
                }}
              >
                {formatCurrency(subtotal)}
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography
                sx={{
                  fontWeight: '600',
                  color: '#393A3D',
                  width: '100%',
                }}
              >
                Shop supply fees
              </Typography>
              <Typography>{formatCurrency(supplyFee)}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography
                sx={{
                  fontWeight: '600',
                  color: '#393A3D',
                  width: '100%',
                }}
              >
                Tax
              </Typography>
              <Typography>{formatCurrency(tax)}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2,
                mt: 2,
                p: 1,
                borderRadius: '12px',
                border: '1px solid #EAECF0',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  color: '#393A3D',
                  width: '100%',
                }}
              >
                Total
              </Typography>
              <Typography
                sx={{
                  fontWeight: '600',
                  color: '#DB5D08',
                }}
              >
                {formatCurrency(total)}
              </Typography>
            </Box>
            {offer.offer.labor_rate && (
              <Box>
                <Typography
                  sx={{
                    fontWeight: '500',
                    color: '#475467',
                    width: '100%',
                  }}
                >
                  Labor rate: {formatCurrency(offer.offer.labor_rate)}/hr
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <Divider />
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="outlined"
            color="warning"
            onClick={handleOpenFeedbackForShopModal}
            sx={{
              width: '100%',
              borderRadius: 2,
              textTransform: 'none',
              mr: 2,
              padding: '8px 16px',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: 'rgba(255, 165, 0, 0.2)',
              },
            }}
          >
            Send back for shop adjustments
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={handleOpenApprovalModal}
            sx={{
              width: '100%',
              borderRadius: 2,
              textTransform: 'none',
              padding: '8px 16px',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: 'rgba(255, 165, 0, 0.5)',
              },
            }}
          >
            Approve and send to customer
          </Button>
        </Box>
        <FeedbackForShopModal
          open={feedbackForShopModalOpen}
          onClose={handleCloseFeedbackForShopModal}
          onConfirm={handleRejectAction}
        />
        <ApprovalModal
          open={approvalModalOpen}
          onClose={handleApprovalCloseModal}
          onConfirm={handleApprovalConfirmAction}
        />
      </Box>
    </Modal>
  )
}

export default InvoiceReportModal
