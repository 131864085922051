import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Container,
  Input,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  TableCell,
  tableCellClasses,
  TablePagination,
} from '@mui/material'
import Sidebar from '../../components/navbar/sidebar'
import { SnackbarProvider } from 'notistack'
import { NewServiceModal } from './NewServiceModal'
import { useListServicesQuery } from '../../redux/api/services/service.api'
import { ServiceListItem } from '../../redux/api/services/service.types'
import moment from 'moment-timezone'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const formatDateTime = (dateString: string): string => {
  return moment(dateString).format('MMMM D @ h:mma')
}

const ServicesPage: React.FC = () => {
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(8)
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search)
    }, 300) // Debounce timeout in milliseconds

    return () => {
      clearTimeout(handler)
    }
  }, [search])

  const { data, isFetching } = useListServicesQuery({
    page,
    limit: rowsPerPage,
    search: debouncedSearch,
  })

  const totalRows = data?.count ?? 0

  const handleChangePage = (event: unknown, newPage: number) => {
    if (!isFetching) {
      setPage(newPage)
    }
  }

  const onNewService = useCallback(() => {
    console.log('New service')
  }, [])

  const onServiceClick = useCallback((serviceId: string) => {
    setSelectedId(serviceId)
  }, [])

  const onModalCanceled = useCallback(() => {
    setSelectedId(undefined)
  }, [])

  return (
    <Container
      maxWidth={false}
      style={{
        maxWidth: '90%',
        display: 'flex',
        marginLeft: '100px',
        marginBottom: '30px',
        marginTop: '30px',
      }}
    >
      <SnackbarProvider maxSnack={5}>
        <Sidebar />
      </SnackbarProvider>
      <Box style={{ flex: 1 }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <Typography variant="h4" style={{ marginBottom: '20px' }}>
            Services
          </Typography>
          <NewServiceModal onSuccess={onNewService} onCanceled={onModalCanceled} id={selectedId} />
        </Box>
        <Input
          placeholder="Search services"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ marginBottom: '20px' }}
        />
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ overflowX: 'auto' }}>
            <Table stickyHeader aria-label="sticky table" style={{ minWidth: '1000px' }}>
              <TableHead>
                <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.items.map((row: ServiceListItem) => (
                  <StyledTableRow key={row.id} onClick={() => onServiceClick(row.id)}>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.type}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
          />
        </Paper>
      </Box>
    </Container>
  )
}

export default ServicesPage
