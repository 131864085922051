import React, { ChangeEvent } from 'react';
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

type ServicesSelectProps = {
  value: string;
  onChange: (value: string) => void;
}

export const ServiceTypeSelect = ({ value, onChange }: ServicesSelectProps) => {

  const handleChange = (e: SelectChangeEvent<string>) => {
    onChange(e.target.value as string);
  }

  return (
    <Select
      size='small'
      fullWidth
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      label="Age"
      onChange={handleChange}
    >
      <MenuItem value='GENERAL'>GENERAL</MenuItem>
      <MenuItem value='ENGINE'>ENGINE</MenuItem>
      <MenuItem value='TIRE'>TIRE</MenuItem>
      <MenuItem value='DIAGNOSIS'>DIAGNOSIS</MenuItem>
    </Select>
  );
};
