import React, { useCallback, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Box,
  styled,
  TablePagination,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import Sidebar from '../../components/navbar/sidebar'
import 'react-datepicker/dist/react-datepicker.css'
import socket from '../../sockets/socket'
import { SnackbarProvider } from 'notistack'
import FilterDrawer from './filter'
import { FilterValues } from '../vehicle/models/create-consumer-vehicle'
import { Request } from './models/consumer-request'
import moment from 'moment-timezone'
import { apiCall } from '../../components/api/api'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const formatDateTime = (dateString: string): string => {
  return moment(dateString).format('MMMM D @ h:mma')
}

const HomePage: React.FC = () => {
  const [data, setData] = useState<Request[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(8)
  const [totalRows, setTotalRows] = useState(0)
  const [filters, setFilters] = useState<FilterValues | null>(null)

  const getDropOffDateTime = (service_request: any) => {
    if (
      !service_request.drop_off_date ||
      !service_request.drop_off_time ||
      service_request.drop_off_time.length === 0
    ) {
      console.error('Invalid drop-off date or time')
      return null
    }
    const [datePart] = service_request.drop_off_date.split('T')
    const timeStr = service_request.drop_off_time[0].split(' ')[0]

    const [year, month, day] = datePart.split('-').map((num: any) => parseInt(num, 10))
    const period = service_request.drop_off_time[0].split(' ')[1]
    let [hours, minutes] = timeStr.split(':').map((str: string) => parseInt(str, 10))

    if (period === 'PM' && hours < 12) hours += 12
    if (period === 'AM' && hours === 12) hours = 0

    const dropOffDate = new Date(year, month - 1, day, hours, minutes, 0, 0)

    return dropOffDate.toISOString()
  }

  const fetchData = useCallback(
    async (filters?: FilterValues) => {
      const token = localStorage.getItem('userToken')
      if (token) {
        try {
          const queryParams = new URLSearchParams({
            limit: rowsPerPage.toString(),
            page: (page + 1).toString(),
          })

          if (filters) {
            ;['metroAreas', 'requestedServices', 'status'].forEach((filterKey) => {
              const filterValue = filters[filterKey as keyof typeof filters]
              if (typeof filterValue === 'object' && !Array.isArray(filterValue)) {
                const selectedKeys = Object.keys(filterValue).filter((key) => filterValue[key])
                if (selectedKeys.length > 0) {
                  queryParams.append(filterKey, selectedKeys.join(','))
                }
              }
            })

            const quoteRange = filters.quoteRange
            if (quoteRange && (quoteRange[0] !== 0 || quoteRange[1] !== 1000)) {
              queryParams.append('quoteRange', quoteRange.join(','))
            }
          }

          const response = await apiCall(`admin/consumer-request?${queryParams.toString()}`, 'get')
          if (response.items) {
            const itemsWithDateTime = response.items.map((item: any) => ({
              ...item,
              requestedDateTime: getDropOffDateTime(item),
            }))
            setData(itemsWithDateTime)
            setTotalRows(response.count)
          }

          setTotalRows(response.count)
        } catch (error) {
          console.error('Failed to fetch data:', error)
        }
      }
    },
    [page, rowsPerPage, filters]
  )

  useEffect(() => {
    fetchData()

    socket.on('new-request', fetchData)
    socket.on('status-update', fetchData)

    return () => {
      socket.off('new-request', fetchData)
      socket.off('status-update', fetchData)
    }
  }, [fetchData])

  const formatDateToLocalTimezone = (dateString: string): string => {
    if (!dateString) {
      return ''
    }

    const date = new Date(dateString)

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'America/Chicago',
      timeZoneName: 'short',
    }
    return date.toLocaleString('en-US')
  }

  const formatDropOffDate = (dateString: string): string => {
    if (!dateString) {
      return ''
    }

    const date = new Date(dateString)

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      timeZone: 'America/Chicago',
      timeZoneName: 'short',
    }

    return date.toLocaleDateString('en-US', options)
  }

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleApplyFilters = (filters: any) => {
    fetchData(filters)
  }

  return (
    <Container
      maxWidth={false}
      style={{
        maxWidth: '90%',
        display: 'flex',
        marginLeft: '100px',
        marginBottom: '30px',
        marginTop: '30px',
      }}
    >
      <SnackbarProvider maxSnack={5}>
        <Sidebar />
      </SnackbarProvider>
      <Box style={{ flex: 1 }}>
        <Typography variant="h4" style={{ marginBottom: '20px' }}>
          Requests
        </Typography>
        <FilterDrawer onApplyFilters={handleApplyFilters} />
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ overflowX: 'auto' }}>
            <Table stickyHeader aria-label="sticky table" style={{ minWidth: '1000px' }}>
              <TableHead>
                <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Created by</StyledTableCell>
                  <StyledTableCell>Created on</StyledTableCell>
                  <StyledTableCell>Vehicle (VIN)</StyledTableCell>
                  <StyledTableCell>Vehicle ID</StyledTableCell>
                  <StyledTableCell>Vehicle location</StyledTableCell>
                  <StyledTableCell>Requested services</StyledTableCell>
                  <StyledTableCell>Preferred drop-off times</StyledTableCell>
                  <StyledTableCell>Shops made offers (quote)</StyledTableCell>
                  <StyledTableCell>Expires at</StyledTableCell>
                  <StyledTableCell>Selected offer</StyledTableCell>
                  <StyledTableCell>Included Telle shop</StyledTableCell>
                  <StyledTableCell>Order status</StyledTableCell>
                  <StyledTableCell>Coords</StyledTableCell>
                  <StyledTableCell>Metro Area</StyledTableCell>
                  <StyledTableCell>Valet info</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row: Request) => (
                  <StyledTableRow key={row.consumer_id}>
                    <StyledTableCell>{row.consumer_id}</StyledTableCell>
                    <StyledTableCell>{row?.phone_number ?? row?.email}</StyledTableCell>
                    <StyledTableCell>{formatDateToLocalTimezone(row.created_at)}</StyledTableCell>
                    <StyledTableCell>
                      {row.vehicle_details} ({row.vin ?? 'NO VIN'}) {row.vehicle_tires?.join(', ')}
                    </StyledTableCell>
                    <StyledTableCell>{row?.vehicle_name}</StyledTableCell>
                    <StyledTableCell>{row?.vehicle_location_name}</StyledTableCell>
                    <StyledTableCell>
                      {row?.requested_services ? row?.requested_services?.join(', ') : ''}
                    </StyledTableCell>
                    <StyledTableCell>
                      <div>{row.requestedDateTime && formatDateTime(row.requestedDateTime)}</div>
                    </StyledTableCell>
                    <StyledTableCell>{row.offers.join(', ')}</StyledTableCell>
                    <StyledTableCell>{formatDateToLocalTimezone(row.expires_at)}</StyledTableCell>
                    <StyledTableCell>{row.accepted_offer}</StyledTableCell>
                    <StyledTableCell>{row.included_provider_branch_names}</StyledTableCell>
                    <StyledTableCell>{row.status}</StyledTableCell>
                    <StyledTableCell>
                      {row.coords?.latitude}{' '}
                      {row.coords?.longitude ? `, ${row.coords?.longitude}` : ''}
                    </StyledTableCell>
                    <StyledTableCell>{row.metro_area}</StyledTableCell>
                    <StyledTableCell>
                      {row.location_name ? row.location_name + ' / ' + row.location_address : ''}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Container>
  )
}

export default HomePage
